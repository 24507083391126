/** @format */

import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import DeviceDetector from 'device-detector-js';
import './style.css';

function App() {
	const [input, setInput] = useState('');
	const uniqueId = uuidv4();
	const deviceDetector = new DeviceDetector();
	const userAgent =
		'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/69.0.3497.81 Safari/537.36';
	const device = deviceDetector.parse(userAgent);
	const [error, setError] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [data, setData] = useState<{ mobileNumber: string; status: string }>();
	const [status, setStatus] = useState(false);

	useEffect(() => {
		const transactionId = window.localStorage.getItem('transactionId');
		if (!transactionId) return;
		else {
			const urlParams = new URLSearchParams(window.location.search);
			const correlationId = urlParams.get('transactionId');
			const code = urlParams.get('code');
			const error = urlParams.get('error');
			const errorMessage = urlParams.get('errorMessage');
			if (error && errorMessage) {
				setError(error);
				setErrorMessage(errorMessage);
			} else {
				fetch(
					`https://eoxnbprjlojbazb.m.pipedream.net/v2/auth/userinfo?code=${
						code || ''
					}&correlationId=${correlationId || transactionId}`,
				)
					.then((response) => {
						setStatus(true);
						window.localStorage.removeItem('transactionId');
						return response.json();
					})
					.then((d) => {
						console.log(d);
						setStatus(false);
						setData(d);
					})
					.catch((e) => {
						setError(e);
					});
			}
		}
	}, []);

	const handleClick = () => {
		//@ts-ignore
		window.localStorage.setItem('transactionId', uniqueId);
		window.location.assign(
			`https://api.sandbox.bureau.id/v2/auth/initiate?clientId=512819f8-55a7-467e-a067-a6b7fb0606a3&correlationId=${uniqueId}&msisdn=${input}`,
		);
	};

	console.log(status);

	return (
		<>
			<nav className='nav'>
				<img
					src='https://uploads-ssl.webflow.com/61a5d188c74343756e97d881/61b9c6ee6516da8261d3e3b5_bureau_logohorizontal_bw_black.svg'
					alt='bureau'
				/>
			</nav>
			<div className='container'>
				<div className='card'>
					<div className='card-info'>
						<h1 className='card-header'>OTL Web</h1>
						<div className='device-info'>
							<h1 className='browser'>
								Browser:{' '}
								<span className='browser-text'>{`${device?.client?.name} ${device?.client?.version}`}</span>
							</h1>
							<h1 className='os'>
								OS: <span className='os-text'>{`${device?.os?.name} ${device?.os?.version}`}</span>
							</h1>
						</div>
					</div>
					{data ? (
						<div className='success-div'>
							<h1 className='response-title'>Status: {data.status}</h1>
							<h1 className='response-subtitle'>Mobile Number: {data?.mobileNumber} </h1>
							<button className='button' onClick={() => window.location.reload()}>
								Go Back
							</button>
						</div>
					) : (
						<form className='form'>
							<label>Phone Number: </label>
							<input
								type='text'
								name='phone'
								id='phone'
								placeholder='XXXXXXXXXX'
								className='input'
								onChange={(e) => setInput(e.target.value)}
								required
							/>
							<p className='span'>
								Note: Phone number needs to be in international format (e.g. IN 91XXXXXXXXXX)
							</p>
							<button className='button' type='button' id='verify-number' onClick={handleClick}>
								Verify my phone number
							</button>
						</form>
					)}
					{error && (
						<div className='error-div'>
							<h1 className='response-title'>Error Code: {error}</h1>
							<h1 className='response-subtitle'>Error Message: {errorMessage} </h1>
						</div>
					)}
					{status && <h2>Fetching Response...</h2>}{' '}
				</div>
			</div>
		</>
	);
}

/**
 * 
 * {error && (
						<div className='error-div'>
							<h1 className='response-title'>Error Code: {error}</h1>
							<h1 className='response-subtitle'>Error Message: {errorMessage} </h1>
						</div>
					)}
					{status && <h2>Fetching Response...</h2>}{' '}
					{data && (
						<div className='success-div'>
							<h1 className='response-title'>Status: {data.status}</h1>
							<h1 className='response-subtitle'>Mobile Number: {data?.mobileNumber} </h1>
						</div>
					)}
 * 
 * 
 */

export default App;
